import Vue from 'vue'
import httpClient from "@/utils/httpClient";

const dashboardState = Vue.observable({
    "dashboard": []
});


export const dashboardGetters = {
    dashboard: () => dashboardState.dashboard
}

export const dashboardMutations = {

    setDashboard(dashboard) {
        dashboardState.dashboard = dashboard;
    }
}

export const dashboardActions = {
    async fetchDashboard(){
        let res = await httpClient({
            url: process.env.VUE_APP_API_URL+ process.env.VUE_APP_API_PATH+'get/dashboard',
            method: 'GET'
        })

        if(res.type === 'success'){
            dashboardMutations.setDashboard(res.dashboard)
        }
    },
}

export default {
    dashboardGetters,
    dashboardMutations,
    dashboardActions
}