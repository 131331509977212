<template>
  <div id="login">
      <div style="float:right;width: 960px;">
        <v-row>
          <v-col class="pt-16" cols="4"></v-col>
          <v-col class="pt-16" cols="6">
            <h1 class="white--text mt-16 mb-0 font-weight-light">Welcome to TITAN</h1>
            <h2 class="white--text mb-12 font-weight-light">AI-Based Eye Analysis</h2>
            <v-text-field @keyup.enter="login" append-icon="mdi-account" autocomplete="off" clearable label="E-Mail" @keyup="error = false" hide-details="auto" v-model="input.email"></v-text-field>
          </v-col>
          <v-col class="pt-16" cols="2"></v-col>
        </v-row>
        <v-row>
          <v-col class="pt-16" cols="4"></v-col>
          <v-col cols="6">
            <v-text-field @keyup.enter="login" type="password" append-icon="mdi-key" autocomplete="off" clearable @keyup="error = false" label="Password" hide-details="auto" v-model="input.password"></v-text-field>
          </v-col>
          <v-col class="pt-16" cols="2"></v-col>
        </v-row>
        <v-row>
          <v-col class="pt-2" cols="4"></v-col>
          <v-col cols="6">
            <v-alert outlined dense transition="appear" v-model="error">
              <v-row dense>
                <v-col cols="1">
                  <v-icon size="30" color="error">mdi-alert</v-icon>
                </v-col>
                <v-col cols="10" class="body-1" style="line-height: 30px" >
                  <div class="pl-4 block">
                    Login failed. Please try again or register.
                  </div>
                </v-col>
              </v-row>
            </v-alert>
            <v-row>
              <v-col cols="2">
                <v-btn depressed color="primary" @click="login">Login</v-btn>
              </v-col>
              <v-col cols="3">
                  <v-btn depressed color="secondary" @click="showRegisterPopup = true">Register</v-btn>
              </v-col>
              <v-col cols="4">
                <v-hover v-slot="{ hover }" open-delay="0" >
                  <div @click="showForgotPWPopup = true" class="inline-block caption pt-2 cursor-pointer" :class="hover ? 'text-decoration-underline' : ''">Forgot password?</div>
                </v-hover>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="pt-16" cols="2"></v-col>
        </v-row>
      </div>


<!--
******************************************************************************************************************************
REGISTER Popup
******************************************************************************************************************************
-->
    <v-dialog v-model="showRegisterPopup" max-width="750">
      <v-card>
        <v-card-title class="headline">
          <v-row>
            <v-col cols="11">
              Registration Form
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="body-2 multiprintpopup">
          After your registration your account will be approved & <b>you should get your login within 48h</b>. You will receive a notification E-Mail.
          <v-divider class="my-8"></v-divider>
          <v-row>
            <v-col sm class="py-0 my-0" cols="6">
              <v-text-field ref="registeremail" type="email" append-icon="mdi-account" autocomplete="off" clearable label="E-Mail*" hide-details="auto" :rules="emailRules" v-model="registerData.email"></v-text-field>
            </v-col>
            <v-col sm class="py-0 my-0" cols="6">
              <v-text-field type="text" append-icon="mdi-account" autocomplete="off" clearable label="Institute" hide-details="auto" v-model="registerData.company"></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mt-12 mb-8">
            <v-col sm class="py-0 my-0" cols="6">
              <v-text-field type="text" append-icon="mdi-account" autocomplete="off" clearable label="First name*" hide-details="auto" v-model="registerData.firstname"></v-text-field>
            </v-col>
            <v-col sm class="py-0 my-0" cols="6">
              <v-text-field type="text" append-icon="mdi-account" autocomplete="off" clearable label="Last name*" hide-details="auto" v-model="registerData.name"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              min 8 characters - min 1 uppercase, min 1 lowercase, min 1 number, min 1 special character
            </v-col>
          </v-row>
          <v-row class="mt-12 mb-8">
            <v-col sm class="py-0 my-0" cols="6">
              <v-text-field type="password" append-icon="mdi-account" autocomplete="off" clearable label="Password* " hide-details="auto" v-model="registerData.password"></v-text-field>
            </v-col>
            <v-col sm class="py-0 my-0" cols="6">
              <v-text-field type="password" append-icon="mdi-account" autocomplete="off" clearable label="Repeat Password*" hide-details="auto" v-model="registerData.password2"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="danger" depressed @click="showRegisterPopup = false;">Abort</v-btn>
          <v-btn v-if="$refs.registeremail" color="primary" :disabled="$refs.registeremail.hasError" depressed @click="register()">Register</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <!--
    ******************************************************************************************************************************
    FORGOT Popup
    ******************************************************************************************************************************
    -->
    <v-dialog v-model="showForgotPWPopup" max-width="500">
      <v-card>
        <v-card-title class="headline">
          <v-row>
            <v-col cols="11">
              Forgot your password? No problem!
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="body-2 multiprintpopup">
          Please enter your mail adress. You will receive an e-mail with a confirmation link. As soon as you have confirmed your address, you will recieve another e-mail with your new password.
          <v-divider class="my-8"></v-divider>
          <v-row>
            <v-col sm class="py-0 my-0" cols="10">
              <v-text-field ref="forgotinput" type="email" append-icon="mdi-account" autocomplete="off" clearable label="E-Mail" hide-details="auto" :rules="emailRules" v-model="forgotmail"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="danger" depressed @click="showForgotPWPopup = false;">Abort</v-btn>
          <v-btn v-if="$refs.forgotinput" color="primary" :disabled="$refs.forgotinput.hasError" depressed @click="sendResetLink()">Send password link</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { loginUser } from '../utils/auth'
import httpClient from "../utils/httpClient";
export default {
  name: 'Login',
  data() {
    return {
      showRegisterPopup: false,
      error: false,
      forgotmail: '',
      registerData: {
        email: '',
        company: '',
        firstname: '',
        lastname: '',
        password: '',
        password2: ''
      },
      emailRules: [
        v => !!v || 'Please enter a valid e-mail address',
        v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'Bitte geben Sie eine gültige Mailadresse ein',
      ],
      showForgotPWPopup: false,
      input: {
        email: "",
        password: ""
      }
    }
  },
  methods: {
    async login() {
      try {
        await loginUser(this.input.email, this.input.password)
        this.$router.push('/dashboard')
      }
      catch (err) {
        this.error = true;
      }
    },

    async register(){
      const res = await httpClient({
        url: process.env.VUE_APP_API_URL+'register',
        method: 'post',
        data: this.registerData
      })

      if(res.type == "success"){
        this.showRegisterPopup = false;
        this.registerData = {
          email: '',
          company: '',
          firstname: '',
          lastname: '',
          password: '',
          password2: ''
        }
      }
    },

    async sendResetLink(){
      const res = await httpClient({
        url: process.env.VUE_APP_API_URL+'send/resetlink',
        method: 'post',
        data: { 'mail': this.forgotmail}
      })

     this.showForgotPWPopup = false;
    }

  }
}
</script>
<style scoped>
  #login{
    height: 100%;
    background-image: url("../assets/img/login-bg-2021.jpg");
    background-size: cover;
  }
  h1{
    margin-bottom: 60px;
    margin-top: 50px;
    font-size: 55px;
    color: #000;
    line-height: 60px;
    font-weight: 300;
  }
  .v-text-field {
    padding-top: 0 !important;
    margin-top: 0;
  }
</style>