import httpClient from "./httpClient";
const actionScope = `loader`;

export function setupInterceptors({ dispatch }) {
    let requestsPending = 0;
    const req = {
        pending: () => {
            requestsPending++;
            dispatch(`${actionScope}/show`);
        },
        done: () => {
            requestsPending--;
            if (requestsPending <= 0) {
                dispatch(`${actionScope}/hide`);
            }
        }
    };

    httpClient.interceptors.request.use(
        config => {
            req.pending();

            return config;
        },
        error => {

            requestsPending--;
            req.done();

            return Promise.reject(error);
        }
    );
    httpClient.interceptors.response.use(
        ({ data }) => {
            req.done();
            //normale fehlermeldung oder fehler popup aufgrund LOCK (da zb. offener WK existiert & Planungsdaten angepasst werden möchten)
            dispatch(`${actionScope}/hide`);
            if(data.message){
                dispatch(`${actionScope}/setmsg`,data.message);
                dispatch(`${actionScope}/setmsgtype`,data.type);
                dispatch(`${actionScope}/setmsgvisible`,true);
            }

            return Promise.resolve(data);
        },
        (error) => {
            dispatch(`${actionScope}/hide`);
            console.log(error);
            if(error.response.status == 401 || error.response.status == 403){
                window.location.href = '/';
            }

            if(error.message){
                dispatch(`${actionScope}/setmsg`,error.response.data.message);
                dispatch(`${actionScope}/setmsgtype`,'error');
                dispatch(`${actionScope}/setmsgvisible`,true);
            }

            req.done();

            return Promise.reject(error);
        }
    );
}