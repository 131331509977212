<template>
  <div id="account">
    <v-container>
      <v-row>
        <v-col cols="12">
          <h1 class="font-weight-light primary--text mb-0">
            Activity Log
          </h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <div class="flex d-inline-flex">
            <v-chip
                class="mt-6 mr-6 flex"
                label
                rounded
                color="secondary"
                small
            >
              {{ filteredLog.length }}
            </v-chip>
          </div>
          <div class="flex d-inline-flex" style="width: 450px;">
            <v-text-field v-model="search" clearable light placeholder="Search User or activity" autocomplete="off"></v-text-field>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card flat outlined class="px-8 py-8">
            <v-row>
              <v-col cols="12">
                <v-row>
                  <v-col>
                    <h3 class="mb-2">
                      What's up
                    </h3>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="2">
                    <span class="font-weight-bold caption">
                      Date
                    </span>
                  </v-col>
                  <v-col cols="2">
                    <span class="font-weight-bold caption">
                      User
                    </span>
                  </v-col>
                  <v-col cols="3">
                    <span class="font-weight-bold caption">
                      Model
                    </span>
                  </v-col>
                  <v-col cols="2">
                    <span class="font-weight-bold caption">
                      Action
                    </span>
                  </v-col>
                  <v-col cols="3">
                    <span class="font-weight-bold caption">
                      Target Info
                    </span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <div v-for="(l,index) in paginatedData" :key="'log_'+index">
                      <v-row>
                        <v-col cols="2">
                          <span class="caption">
                             {{ l.created_at }}
                          </span>
                        </v-col>
                        <v-col cols="2">
                          <div v-html="getHighlightSearchName(l.user)" class="body-2">

                          </div>
                        </v-col>
                        <v-col cols="3">

                          <v-chip
                              class="mt-1 pb-2 pt-1"
                              label
                              :class="statusColor(l.model)"
                              small>
                            <div v-html="getHighlightSearchName(l.model)" class="body-2">

                            </div>
                          </v-chip>

                        </v-col>
                        <v-col cols="2">
                           <div v-html="getHighlightSearchName(l.action)" class="body-2">
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <span class="body-2">
                             {{ l.target ? l.target.name : '' }}
                          </span>
                        </v-col>
                      </v-row>
                    </div>
                    <v-pagination :key="'pag'+pageCount" total-visible="5" v-model="pageNumber" light  color="secondary" :length="pageLength" @input="nextPage" class="mt-8" />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
          <br><br>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import {logActions, logGetters} from "../components/observables/activitylog";

export default {
  name: 'ActivityLog',
  data() {
    return {
      pageNumber: 1,
      search: '',
      size: 20,
    }
  },

  watch:{
    search(){
      this.pageNumber = 1;
    }
  },

  computed:{
    ...logGetters,

    paginatedData() {
      if(this.filteredLog.length > 0){
        const start = this.pageNumber * this.size - this.size,
            end = start + this.size;
        return this.filteredLog.slice(start, end);
      }
    },

    pageCount() {
      let l = this.filteredLog.length,
          s = this.size;
      return Math.ceil(l / s);
    },

    pageLength(){
      return Math.ceil(this.filteredLog.length/this.size)
    },

    filteredLog() {

      if (!this.search) {
        this.search = '';
      }

      if (this.log !== undefined) {

        var filteredLog = this.log.filter(o => {
          try {
            return (
                (o.user.toLowerCase().indexOf(this.search.toLowerCase()) > -1 || o.action.toLowerCase().indexOf(this.search.toLowerCase()) > -1 || o.model.toLowerCase().indexOf(this.search.toLowerCase()) > -1)
            )
          } catch (e) {
            return false;
          }
        })
        return filteredLog;
      } else {
        return false;
      }
    }
  },

  methods:{
    ...logActions,

    nextPage(page) {
      this.pageNumber = page;
    },

    statusColor(model){

      if(model == 'analysis'){
        return 'bg-green'
      }

      if(model == 'preprocessor'){
        return 'bg-blue'
      }
    },

    getHighlightSearchName(textVal) {

      if (this.search == '') {
        return textVal;
      }

      return textVal.replace(new RegExp(this.search, "gi"), match => {
        return '<span class="highlightText">' + match + '</span>';
      });
    }
  },

  created() {
    this.fetchLog();
  }
}
</script>