import httpClient from "./httpClient";
import {store} from "../store/index";

const benutzerScope = `benutzer`;

export function loginUser(username, password) {

    return new Promise(async (resolve, reject) => {
        try {
            await httpClient({
                url: process.env.VUE_APP_API_URL+'sanctum/csrf-cookie',
                method: 'get',
            })

            let res = await httpClient({
                url:  process.env.VUE_APP_API_URL+'login',
                method: 'POST',
                data: {
                    email: username,
                    password: password,
                }
            })

            if(res.type === "success"){
                store.dispatch(`${benutzerScope}/setID`,res.uid);
                store.dispatch(`${benutzerScope}/setInfo`,res.info);
            }else{
                reject()
            }

            resolve()
        }
        catch (err) {
            reject(err)
        }
    })
}

export function logoutUser() {

    store.dispatch(`${benutzerScope}/setID`,false);
    localStorage.clear();
    sessionStorage.clear()
    httpClient({
        url: process.env.VUE_APP_API_URL+'logout',
        method: 'GET'
    })
}

export function isLoggedIn() {
    return store.state.benutzer.id;
}