<template>
    <v-row>
      <v-col>
        <span v-if="eyedata.length === 0">
          No stacks available please add some data
        </span>
        <div v-else>
          <div v-for="(ed,index) in eyedata" :key="'eyedata_'+aitype+'_'+index">
            <v-row class="mt-2 mb-1">
              <v-col cols="3">
                <span class="pr-2">{{ ed.name }}</span>
                <v-chip
                    class="ma-2 ml-0"
                    label
                    x-small
                >
                  {{ ed.fileCount }} file<span v-if="ed.fileCount > 1">s</span>
                </v-chip>
                <br>
                <span class="caption">
                  {{ ed.stack_id }}
                </span>
              </v-col>
              <v-col cols="2">
                <span class="caption" v-if="!ed.preprocessor">
                  {{ ed.description }}
                </span>
                <div v-else class="caption">
                  <v-chip
                      class="mt-0 bg-blue"
                      label
                      x-small
                  > {{ ed.preprocessor.name }}
                  </v-chip>
                  <br>

                  <v-chip
                      class="mt-4"
                      label
                      :class="statusColor(ed.preprocessor.status)"
                      x-small
                  >Status: {{ ed.preprocessor.status }}
                  </v-chip>
                </div>
              </v-col>
              <v-col cols="2">
                <span class="caption">
                  uploaded <br>{{ moment(ed.created_at).locale("de").format('DD.MM.YYYY - HH:mm') }}
                </span>
              </v-col>
              <v-col cols="2" :key="ed.stack_id+'_'+rerenderTimer">
                <div class="caption">
                  <span>auto delete in</span><br>
                  <v-chip
                      class="mt-2 bg-red text--white"
                      label
                      x-small
                  > {{ diffTime(ed.created_at) }}
                  </v-chip>
                </div>
              </v-col>
              <v-col cols="1" class="mr-3">
                <v-btn class="mt-2 ml-0 px-3" v-if="!ed.preprocessor || ed.preprocessor.status === 'SUCCESS'"  @click="requestDownload(ed)" color="grey--lighten" depressed small>
                  Download
                </v-btn>
              </v-col>
              <v-col cols="1" class="caption">
                <v-btn  @click="prepareDeleteStack(ed.id)" dense depressed small color="secondary" class="mt-2">
                  <v-icon size="20">
                    mdi-delete-outline
                  </v-icon>
                </v-btn>
              </v-col>

            </v-row>
            <v-divider light></v-divider>
          </div>
        </div>
      </v-col>
    </v-row>
</template>
<script>

  import moment from "moment";
  import {eyedataActions} from "../observables/eyedata";
  import httpClient from "../../utils/httpClient";

  export default {
    name: "EyeDataList",
    props: ['aitype','eyedata'],
    data(){
      return {
        moment: moment,
        rerenderTimer: 0,
      }
    },
    methods:{
      ...eyedataActions,

      async requestDownload(ed) {
        let res = await httpClient({
          url: process.env.VUE_APP_API_URL + process.env.VUE_APP_API_PATH + 'request/download',
          method: 'post',
          responseType: 'blob',
          data: {eyedata: ed}
        })

        if (res != 'false' && res.size > 20) {

          var fileURL = window.URL.createObjectURL(new Blob([res], {type: 'application/zip'}));
          var fileLink = document.createElement('a');

          fileLink.href = fileURL;
          fileLink.setAttribute('download', 'titan-download-' + moment(this.month).format('YYYY-MM-DD-hh-mm-ss') + '.zip');
          document.body.appendChild(fileLink);
          fileLink.click();

        } else {
          this.$store.dispatch(`loader/hide`);
          this.$store.dispatch(`loader/setmsg`, 'no download available');
          this.$store.dispatch(`loader/setmsgtype`, 'error');
          this.$store.dispatch(`loader/setmsgvisible`, true);
        }
      },

      statusColor(status){

        if(status === 'SUCCESS'){
          return 'bg-green'
        }

        if(status === 'DELETED' || status === 'ERROR'){
          return 'bg-red'
        }

        if(status === 'WAITING' || status === 'PROCESSING'){
          return 'bg-orange'
        }

      },

      diffTime: function(stackDate){

        var a = moment(stackDate).add(5,'days');
        var b = moment();

        var duration = moment.duration(a.diff(b));

        return Math.floor(duration.asDays())+' days '+duration.hours()+'h '+duration.minutes()+'m ' +duration.seconds()+'s'
      },

      prepareDeleteStack(id){
        this.$confirm('Really delete this image stack and all its content?', {
          title: 'Attention',
          buttonTrueText: 'Delete',
          buttonFalseText: 'Abort'
        }).then(async confirmed => {
          if (confirmed) {
            this.deleteEyedata(id);
          }
        })
      }
    },
    mounted() {
      //update alle 30sek time
      var self = this;
      this.timer = setInterval(function(){
        self.rerenderTimer = self.rerenderTimer +1;
      }, 1000)
    },
    destroyed: function(){
      clearInterval( this.timer )
    },
  };
</script>
<style lang="scss">

</style>