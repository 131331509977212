<template>
  <div class="multi-endpoints">
    <v-row>
      <v-col>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn depressed color="secondary" class="mb-6" @click="addInstance()" v-bind="attrs" v-on="on">
              <v-icon>
                {{ addicon }}
              </v-icon>
            </v-btn>
          </template>
          <span>
           You can add multiple instances and submit at the same time.<br> Just click this add button multiple times."
          </span>
        </v-tooltip>
      </v-col>
    </v-row>
    <div v-for="(i,index) in instanceCount" class="mb-8">
      <v-divider light class="mb-4"></v-divider>
      <v-row>
        <v-col cols="1">
          <v-badge
              :color="aitype == 'preprocessor' ? 'blue' : 'green'"
              class="mt-10"
              :content="(index+1)"
          >
          </v-badge>
        </v-col>
        <v-col cols="3">
          <v-text-field v-model="instances[index]['name']" maxlength="20" label="Name*"></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-select
              v-model="instances[index]['eyedata']"
              :items="eyedata"
              label="Eye data"
              item-text="name"
              item-value="id"
          ></v-select>
        </v-col>
          <v-col cols="3">
            <v-select
                v-model="instances[index]['endpoint']"
                :items="endpoints"
                @change="changeEndpointObject(index)"
                label="AI-Endpoint"
                item-text="name"
                item-value="id"
            ></v-select>
        </v-col>
        <v-col>
          <v-btn class="secondary mt-4" depressed @click="removeInstance(index)" small>
            <v-icon size="16">
              mdi-minus-circle-outline
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="instances[index]['endpointObject']['description'] && instances[index]['endpointObject']['description'] != ''">
        <v-col cols="1"></v-col>
        <v-col class="mt-0 pt-0">
          <div class="caption">
            <b>Endpoint description</b>: {{ instances[index]['endpointObject']['description'] }}
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="1"></v-col>
        <v-col v-if="instances[index]['endpoint'] && instances[index]['endpointObject']['eyesensitive'] === 1" cols="3">
          <div style="background: #efefef;padding: 10px 10px 10px 15px">
            <v-select
                v-model="instances[index]['eye']"
                :items="eyetypes"
                label="Left or right eye"
                item-text="name"
                item-value="id"
            ></v-select>
          </div>
        </v-col>
        <v-col cols="3" v-for="(p,pindex) in instances[index]['endpointObject']['parameters']">
          <div style="background: #efefef;padding: 10px 10px 0 0">
            <v-row>
              <v-col cols="4"></v-col>
              <v-col cols="4">
                <div class="caption">
                  {{ instances[index]['endpointObject']['parameters'][pindex]['key'] }}
                </div>
              </v-col>
              <v-col cols="4">
                <v-text-field type="text" autocomplete="off" v-model="instances[index]['endpointObject']['parameters'][pindex]['defaultvalue']" label="Value"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </div>
    <div v-if="instanceCount > 0">
      <v-divider light class="mb-4"></v-divider>
      <v-row >
        <v-col>
          <v-btn :disabled="isProcessing" @click="process()" depressed color="secondary" class="mt-4">
            <v-icon class="pr-3">
              mdi-rocket-launch-outline
            </v-icon>
            Start processing
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <v-dialog v-model="processModal" persistent max-width="800">
      <v-card>
        <v-card-title class="headline font-weight-normal mb-6">
          <span class="rounded px-2 py-1" :class="aitype == 'analysis' ? 'bg-green' : 'bg-blue'">
              Processing
          </span>
        </v-card-title>


        <v-card-text class="body-2" v-if="!isProcessing">
          <v-row>
            <v-col>
              <div v-if="aitype == 'analysis'">
                Your data will now be sent to our API's. Soon your results will appear under <v-btn depressed color="secondary" x-small link to="/my-analyzes">"My Analyzes"</v-btn><br><br>This may take several minutes. If you have enabled mail notifications in your settings (default), you will receive an E-Mail notification.
                You can check your analyzes / leave this page now if you like.
              </div>

              <div v-else>
                Your data will now be sent to our API's. Soon your results will appear under <v-btn depressed color="secondary" x-small link to="/eye-data">"Eye Data / ImageStacks"</v-btn><br><br>This may take several minutes. If you have enabled mail notifications in your settings (default), you will receive an E-Mail notification.
                You can check your image stack (eye data) / leave this page now if you like.
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text class="body-2" v-else>
          <v-row>
            <v-col>

              Please wait and do not close your browser window until we have submitted your requqest. Depending on the number of files this could take a few minutes.

            </v-col>
          </v-row>
        </v-card-text>


        <v-card-actions v-if="!isProcessing">
          <v-spacer></v-spacer>
          <v-btn color="secondary" depressed @click="processModal = false">OK, close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import {analysisActions} from '../../components/observables/analysis';
import {preprocessorActions} from '../../components/observables/preprocessors'

  export default {
    name: "MultiEndpoints",
    props: ['aitype','endpoints','addicon','eyedata'],
    data(){
      return {
        processModal: false,
        isProcessing: false,
        instanceCount: 0,
        instances: [],
        eyetypes: [
          {
            id: 'left',
            name: 'Left eye'
          },
          {
            id: 'right',
            name: 'Right eye'
          }
        ],
      }
    },
    methods:{
      ...analysisActions,
      ...preprocessorActions,

      addInstance(){
        if(this.instanceCount < 5){
          this.instances.push({
            name: '',
            eye: this.endpoints[0]['eyesensitive'] === 1 ? 'left' : false,
            endpoint: this.endpoints[0]['id'],
            endpointObject: this.endpoints[0],
            eyedata: this.eyedata[0]['stack_id']
          });

          this.instanceCount++;
        }
      },

      removeInstance(index){
          this.instanceCount --;
          this.instances.splice(index,1);
      },

      changeEndpointObject(index){

        var self = this;

        this.instances[index]['endpointObject'] = JSON.parse(JSON.stringify(
            this.endpoints.filter(function (el){
              return el.id === self.instances[index]['endpoint'];
            })[0]
        ));

        this.instances[index]['eye'] = this.instances[index]['endpointObject']['eyesensitive'] === 1 ? 'left' : false;
      },

      async process(){

        this.isProcessing = true;
        this.processModal = true;

        if(this.aitype == 'analysis'){
          var res = await this.createAnalysis(this.instances);
        }else if(this.aitype == 'preprocessor'){
          var res = await this.createPreprocessor(this.instances);
        }

        if(res == 'success'){
          this.isProcessing = false;

          this.instanceCount = 0;
          this.instances = [];
        }else{
          this.isProcessing = false;
        }
      }
    }
  };
</script>
<style lang="scss">
.v-application .multi-endpoints{
    .v-badge__badge{
      font-size: 11px !important;
      color: #000 !important;
    }
}
</style>