import Vue from 'vue'
import httpClient from "@/utils/httpClient";

const eyedataState = Vue.observable({
    "eyedata": [],
    'preprocessed': []
});


export const eyedataGetters = {
    eyedata: () => eyedataState.eyedata,
    preprocessed: () => eyedataState.preprocessed
}

export const eyedataMutations = {

    setEyedata(eyedata) {
        eyedataState.eyedata = eyedata;
    },

    setPreprocessed(preprocessed) {
        eyedataState.preprocessed = preprocessed;
    }
}

export const eyedataActions = {
    fetchEyedata(withUnfinishedPreprocessors = false){
        httpClient({
            url: process.env.VUE_APP_API_URL+ process.env.VUE_APP_API_PATH+'get/eye-data',
            method: 'post',
            data: {withUnfinishedPreprocessors: withUnfinishedPreprocessors}
        }).then(function (res) {
            eyedataMutations.setEyedata(res.eyedata)
            eyedataMutations.setPreprocessed(res.preprocessed)
        })
    },

    async saveEyedata(id,name,description){

        let res = await httpClient({
            url: process.env.VUE_APP_API_URL+ process.env.VUE_APP_API_PATH+'save/eye-data',
            method: 'post',
            data: {id:id,name:name,description:description}
        })

        if(res.type === 'success'){
            eyedataMutations.setEyedata(res.eyedata)
        }

        return res.type;
    },

    deleteEyedata(id){
        httpClient({
            url: process.env.VUE_APP_API_URL+ process.env.VUE_APP_API_PATH+'delete/eye-data',
            method: 'post',
            data: { 'id': id }
        }).then(function (res) {
            if(res.type === 'success'){
                eyedataMutations.setEyedata(res.eyedata)
            }
        })
    },
}

export default {
    eyedataState,
    eyedataGetters,
    eyedataMutations,
    eyedataActions
}