<template>
  <div id="account">
    <v-container>
      <v-row>
        <v-col cols="12">
          <h1 class="font-weight-light primary--text mb-0">
            My Profile
          </h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card flat outlined class="px-8 py-8">
            <v-row>
              <v-col cols="12">
                <h3 class="mb-2">
                  Change password
                </h3>
                <span class="caption">
                  min 8 characters - min 1 uppercase, min 1 lowercase, min 1 number, min 1 special character
                </span>
                <v-divider class="my-4"></v-divider>
                <v-row>
                  <v-col cols="4">
                    <v-text-field class="mb-0"
                                  autocomplete="off"
                                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                  :type="show ? 'text' : 'password'"
                                  name="input-10-1"
                                  label="New password"
                                  v-model="pw1"
                                  :rules="[rules.required, /*rules.min,*/rules.strength]"
                                  @click:append="show = !show"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="1"></v-col>
                  <v-col cols="4">
                    <v-text-field
                        autocomplete="off"
                        :type="show ? 'text' : 'password'"
                        name="input-10-2"
                        label="Repeat new password"
                        v-model="pw2"
                        :error="checkPasswords"
                        @click:append="show2 = !show2"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="mb-8">
                  <v-col cols="3">
                    <v-btn color="secondary" class="mt-0 mb-4" depressed @click="savePassword();">Save new password
                    </v-btn>
                  </v-col>
                </v-row>
                <v-divider light></v-divider>
                <v-row>

                  <v-col>
                    <label class="text-caption">
                      <v-icon class="my-6" color="grey">mdi-shield-lock</v-icon>
                      Security Level: <span>{{ score.level }}</span>
                    </label>
                    <v-progress-linear class="mt-2"
                                       :color="score.color"
                                       :value="score.value"
                    ></v-progress-linear>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
          <br><br>
          <v-card flat outlined class="px-8 py-8">
            <v-row>
              <v-col cols="12">
                <h3 class="mb-2">
                  My Data
                </h3>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field v-model="profile.company" clearable light label="Company" autocomplete="off"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field v-model="profile.firstname" clearable light label="First name" autocomplete="off"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="profile.name" clearable light label="Last name" autocomplete="off"></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mb-8">
              <v-col cols="3">
                <v-btn color="secondary" class="mt-0 mb-4" depressed @click="updateProfile(profile);">Save profile
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import zxcvbn from 'zxcvbn';
import {profileActions,profileGetters} from "../components/observables/profile";
export default {
  name: 'MyProfile',
    data() {
      return {
        show: false,
        show2: false,
        pw1: '',
        pw2: '',
        rules: {
          required: value => !!value || 'Please enter password.',
          strength: value => {
            //Min. 8 characters with at least one capital letter, a number and a special character.
            const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\.\,\(\)\/\=\+\-])(?=.{8,})/;
            return (
                pattern.test(value) ||
                "min 8 and one special character (@$!%*#?&)"
            );
          }
        }
      }
  },

  methods: {
    ...profileActions

  },
  computed: {

    ...profileGetters,

    checkPasswords() {
      return this.pw1 !== this.pw2;
    },

    score() {
      const result = zxcvbn(this.pw1);

      switch (result.score) {
        case 4:
          return {
            color: "green",
            value: 100,
            level: 4
          };
        case 3:
          return {
            color: "green",
            value: 75,
            level: 3
          };
        case 2:
          return {
            color: "green",
            value: 50,
            level: 2
          };
        case 1:
          return {
            color: "green",
            value: 25,
            level: 1
          };
        default:
          return {
            color: "green",
            value: 0,
            level: 0
          };
      }
    }
  },

  created() {
    this.fetchProfile()
  }
}
</script>