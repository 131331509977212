<template>
  <div id="account">
    <v-container>
      <v-row>
        <v-col cols="12">
          <h1 class="font-weight-light primary--text mb-0">
            Eye Data / Image Stacks
          </h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card flat outlined class="px-8 py-8">
            <v-row>
              <v-col cols="12">
                <span>
                 <v-icon color="secondary" size="20" style="margin-top: -4px;display: inline-block">
                   mdi-information-outline
                 </v-icon>
                  <span class="pt-2 pl-2">
                     <b>Good to know</b>:<br>
                    <ul class="mt-4 block">
                      <li>Upload data, create analyzes or pass your data trough a preprocessor to use it as a new image stack</li>
                      <li>You can use <span class="bg-blue px-2 py-1 rounded">pre-processed</span> Image Stacks for your <span class="bg-green px-2 py-1 rounded">analyzes</span> as soons as the status is "ready"</li>
                    </ul>
                  </span>
                </span>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card flat outlined class="px-8 py-8">
            <v-row>
              <v-col cols="12">
                <v-btn depressed @click="uploadModal = true" color="secondary">
                  <v-icon>
                    mdi-eye-plus-outline
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <h3>
                  My Eye Data / Image Stacks ({{ eyedata.length }})
                </h3>
              </v-col>
            </v-row>
            <eye-data-list :eyedata="eyedata" aitype="analysis" ></eye-data-list>
            <div class="mt-12">
              <v-row v-if="eyedata.length > 0">
                <v-col cols="8">
                  <v-btn depressed link to="/preprocessors" color="gray" class="font-weight-light  mr-4">
                    <v-icon class="pr-3" color="grey">
                      mdi-file-image-plus-outline
                    </v-icon>
                    Create preprocessor from Eye Data
                  </v-btn>
                  <v-btn depressed link to="/create-analysis" color="gray" class="font-weight-light">
                    <v-icon class="pr-3" color="grey">
                      mdi-chart-box-plus-outline
                    </v-icon>
                    Create analysis from Eye Data
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-card>
          <br><br>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="uploadModal" persistent max-width="800">
      <v-card>
        <v-card-title class="headline font-weight-normal mb-6">
          Add Eye Data
        </v-card-title>
        <v-card-text class="body-2">
          <v-row>
            <v-col>
              <b><span style="color:#ff0000">ATTENTION</span></b> More than 10 files should be uploaded as ZIP FILE containing the image (no folders).<br><br>
              Upload a <b>ZIP file (faster upload)</b> with your images <b>or choose multiple single images (slower upload)</b>. Please do not close your browser until the upload has finished. After the upload you
              can click the "save" button to add the image stack.
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field v-model="stackname" maxlength="20" label="1. Name"></v-text-field>
            </v-col>
          </v-row>
          <v-row  v-if="stackname != ''">
            <v-col>
              <v-textarea v-model="stackdescription" rows="2" label="2. Description"></v-textarea>
            </v-col>
          </v-row>
          <v-row v-if="stackdescription != ''">
            <v-col class="mb-8">
              <div>
                <!-- accept="application/zip" -->
                3. Data
                <v-file-input accept="application/zip,image/bmp,image/png,image/jpg,image/jpeg" multiple prepend-icon="mdi-file-star-outline" type="file" :disabled="running === true"  @change="select"></v-file-input>
                <div class="pl-8 bg-secondary"  v-if="running || finished">
                  <v-progress-linear  light height="30" :class="finished ? 'finished bg-green' : ''" class="text--white" :value="progress">
                    <span v-if="progress < 100 && !finished">
                      <v-icon color="white" size="16">mdi-timer-sand</v-icon>
                       <span class="white--text pl-2"> File {{ (actualFileUploadNo+1) > chunks.length ? chunks.length :  (actualFileUploadNo+1) }} of {{ chunks.length }} | {{ progress }}%</span>
                    </span>
                    <span v-else>
                      <v-icon color="white" size="16">mdi-shield-check-outline</v-icon>
                      <span class="white--text pl-2" v-if="!finished">File ok - please wait...moving file (can take a few minutes)</span>
                      <span class="white--text pl-2" v-else> File {{ (actualFileUploadNo+1) > chunks.length ? chunks.length :  (actualFileUploadNo+1) }} of {{ chunks.length }} | All Files uploaded | You can now save this stack</span>
                    </span>
                  </v-progress-linear>
                  <span class="caption pt-4">
                  </span>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="danger" :disabled="running === true && finished !== true" depressed @click="uploadModal = false">Abort</v-btn>
          <v-btn color="secondary" :disabled="!finished" depressed @click="saveStack();">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>

import httpClient from "../utils/httpClient";
import {eyedataGetters, eyedataActions, eyedataMutations} from '../components/observables/eyedata';
import EyeDataList from "../components/eyedata/EyeDataList";

export default {
  name: 'EyeData',
  components:{
    EyeDataList
  },
  data: () => ({
    stackname: '',
    stackdescription: '',
    uploadModal: false,
    stackID: false,
    files: [],
    chunks: [],
    uploaded: 0,
    finished: false,
    running: false,
    actualFileUploadNo: 0,
    actualChunks: [],
    pendingTimer: false,
    timer: false,
  }),

  watch: {
    actualChunks(n, o) {
      if (n.length > 0) {
        this.upload();
      }
    }
  },

  computed: {
    ...eyedataGetters,

    progress() {
      try{
        return Math.floor((this.uploaded * 100) / this.files[this.actualFileUploadNo].size);
      }catch (e){
        return 0;
      }
    },
    formData() {
      let formData = new FormData;

      formData.set('is_last', this.actualChunks.length === 1);
      formData.set('stackID', this.stackID);
      formData.set('file', this.actualChunks[0], `${this.files[this.actualFileUploadNo].name}.part`);

      return formData;
    },

    pendingStacks(){

      return this.eyedata.filter(function(e) {

        return (e.preprocessor && (e.preprocessor.status === 'WAITING' || e.preprocessor.status === 'PROCESSING' ));

      });
    },
  },

  methods: {
    ...eyedataActions,
    ...eyedataMutations,

    select(files) {
      this.stackID = 'stack-' + this.$store.state.benutzer.id +'_'+ (new Date()).getTime();

      this.uploaded = 0;
      this.files = [];
      this.finished = false;
      this.actualFileUploadNo = 0;
      this.actualChunks = [];
      this.chunks = [];
      var self = this;


      files.forEach((file,i) => {
        self.files[i] = file;
      })

      this.createChunks();
    },
    async upload() {

      this.running = true;

      const res = httpClient({
        url: process.env.VUE_APP_API_URL+ process.env.VUE_APP_API_PATH + 'upload/eye-data',
        method: 'post',
        data: this.formData,
        headers: {
          'Content-Type': 'application/octet-stream'
        },
        onUploadProgress: event => {
          this.uploaded += event.loaded;
        }
      }).then((response) => {
        this.actualChunks.shift();
        if(this.actualChunks.length == 0){
          this.actualFileUploadNo++;
          this.uploaded = 0;
          if(this.chunks[this.actualFileUploadNo] !== undefined) {
            this.actualChunks = this.chunks[this.actualFileUploadNo]
          }

          if(this.actualFileUploadNo == this.chunks.length){
            this.finished = true;
            this.running = false;
          }
        }
      }).catch((error) => {
      });
    },

    async saveStack(){

      let res = await this.saveEyedata(
          this.stackID,
          this.stackname,
          this.stackdescription,
      )

      if(res == 'success'){
        this.stackname = '';
        this.stackdescription = '';
        this.finished = false;
        this.uploadModal = false;
      }
    },

    createChunks() {

      var self = this;
      this.files.forEach((file,p) => {

        self.chunks[p] = [];

        let size = 500000, chunks = Math.ceil(self.files[p].size / size);

        for (let i = 0; i < chunks; i++) {
          self.chunks[p].push(self.files[p].slice(
              i * size, Math.min(i * size + size, self.files[p].size), self.files[p].type
          ));
        }
      })
      this.actualChunks = this.chunks[0];
    },

    async checkPending(){

      if(this.pendingStacks.length > 0){
        let res = await httpClient({
          url: process.env.VUE_APP_API_URL+ process.env.VUE_APP_API_PATH+'check/pending',
          method: 'post',
          data: { instances: this.pendingStacks, responseType: 'eyedata' }
        })

        if(res.type == 'success' && res.eyedata){
          this.setEyedata(res.eyedata);
        }
      }
    }
  },

  created() {
    this.fetchEyedata(true);

    var self = this;

    this.pendingTimer = setInterval(function(){

      self.checkPending();

    }, 8000)
  },

  destroyed: function(){
    clearInterval( this.pendingTimer )
  }
}
</script>
<style lang="scss">
.v-application{

  .finished{
    .v-progress-linear__content{
      *{
        color: #2a2a2a !important;
      }
    }
    .v-progress-linear__background{
      opacity: 0 !important;
    }
  }
}
</style>