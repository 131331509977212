<template>
  <div id="account">
    <v-container>
      <v-row>
        <v-col cols="12">
          <h1 class="font-weight-light primary--text mb-0">
            Pass trough Pre-Processor
          </h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card flat outlined class="px-8 py-8">
            <v-row>
              <v-col cols="12">
                <span>
                 <v-icon color="secondary" size="20" style="margin-top: -4px;display: inline-block">
                   mdi-information-outline
                 </v-icon>
                  <span class="pt-2 pl-2">
                     <b>Good to know</b>:
                     <ul class="mt-4 block">
                      <li>Sent and processed <span class="bg-blue px-2 py-1 rounded">pre-processor</span> requests are saved as new <v-btn depressed color="secondary" small link to="/eye-data">"Eye Data" / ImageStack</v-btn></li>
                       <li>The stack is marked with a blue label and the given name</li>
                       <li>
                         You can then use the new generated stack to create a new <span class="bg-green px-2 py-1 rounded">analysis</span>
                       </li>
                    </ul>
                  </span>
                </span>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card flat outlined class="px-8 py-8">
            <v-row v-if="eyedata.length > 0 && aiendpoints.length > 0">
              <v-col cols="12">
                <multi-endpoints aitype="preprocessor" :eyedata="eyedata" :endpoints="aiendpoints" addicon="mdi-file-image-plus-outline"></multi-endpoints>
              </v-col>
            </v-row>
            <v-divider light class="my-8"></v-divider>
            <v-row>
              <v-col cols="12">
                <v-row>
                  <v-col v-cloak>
                    You have {{ eyedata.length }} image stack<span v-if="eyedata.length > 1">s</span> and {{ aiendpoints.length }} Pre-Processor-Endpoint<span v-if="aiendpoints.length > 1">s</span> available
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-btn depressed link to="/eye-data" color="gray" class="font-weight-light">
                      <v-icon class="pr-3" color="grey">
                        mdi-eye-plus-outline
                      </v-icon>
                      Add more image stacks
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
          <br><br>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import {eyedataGetters, eyedataActions} from '../components/observables/eyedata';
import {aiendpointActions, aiendpointGetters} from "../components/observables/aiendpoints";
import MultiEndpoints from "../components/creation/MultiEndpoints";

export default {
  name: 'PreProcessors',
  components:{
    MultiEndpoints
  },
  data() {
    return {

    }
  },

  computed: {
    ...eyedataGetters,
    ...aiendpointGetters
  },

  methods:{
    ...eyedataActions,
    ...aiendpointActions,
  },

  created() {
    this.fetchEyedata();
    this.fetchLoginUsersAiendpoints('preprocessor');
  }
}
</script>