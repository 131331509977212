import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

const vuetify = new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#613bec',
                secondary: '#9477f6',
                green: '#93e995',
                blue: '#75d1fd',
                white: '#ffffff',
                accent: '#000000',
                error: '#b71c1c',

            },
        },
    },
});

import VuetifyConfirm from 'vuetify-confirm'
Vue.use(VuetifyConfirm, { vuetify })

export default vuetify;