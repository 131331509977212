import Vue from 'vue'
import httpClient from "@/utils/httpClient";

export const preprocessorActions = {
    async createPreprocessor(instances){
        let res = await httpClient({
            url: process.env.VUE_APP_API_URL+ process.env.VUE_APP_API_PATH+'create/preprocessor',
            method: 'post',
            data: {'instances':instances}
        })

        return res.type;
    },
}

export default {
   preprocessorActions
}