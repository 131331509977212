<template>
  <v-app>
    <Loader :visible="loading"/>
    <v-app-bar height="70" app extension-height="120" color="white" v-if="isLoggedIn() && this.$route.name != 'login' && this.$route.name != 'passwortreset'" style="z-index: 2;background: #dcdcdd !important;">
      <v-container>
        <v-row>
         <v-col>
           <h1 class="tooltitle mt-1 font-weight-bold">
             TITAN v2.1<br>
             <span class="font-weight-light">AI-Based Eye Analysis</span>
           </h1>
         </v-col>
        </v-row>
      </v-container>
    </v-app-bar>
    <v-main class="d-none d-lg-flex" :class="this.$route.name != 'login' && this.$route.name != 'passwortreset' ? 'pb-16' : 'pb-0'">
      <v-layout>
        <Navigation v-if="this.$route.name != 'login' && this.$route.name != 'passwortreset'"></Navigation>
      </v-layout>
      <router-view :style="$router.currentRoute.name != 'login' ? 'margin:0 110px' : ''" />
    </v-main>
    <div class="d-lg-none d-print-none" style="padding-top: 100px">
      <v-container>
        <v-row>
          <v-col>
            <h1>
             TITAN
            </h1>
            <h2>
              Warning
            </h2>
            <div>
              TITAN needs a browser width > 1265px. If you have any question please contact support.
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-snackbar v-model="showmsg"  timeout="3500"  color="white" class="black--text" v-if="msg != 'Unauthenticated.'">
      <v-row dense>
        <v-col cols="1">
          <v-icon size="30" v-if="msgtype == 'error'"  color="error" class="pt-2">mdi-alert</v-icon>
          <v-icon size="30" v-if="msgtype == 'success'" color="green" class="pt-0">mdi-check-circle</v-icon>
        </v-col>
        <v-col cols="10" class="body-1" >
          <div class="pl-4 pt-1 block black--text" v-html="msg">
          </div>
        </v-col>
      </v-row>
    </v-snackbar>
  </v-app>
</template>
<script>
import Loader from "@/components/layout/Loader";
import Navigation from "@/components/layout/Navigation";
import { mapState } from "vuex";
import { isLoggedIn } from '@/utils/auth';
export default {
  name: 'App',
  components:{
    Loader, Navigation
  },
  computed: {
    ...mapState("loader", ["loading",'msg','msgtype']),

    showmsg:{
      get () {
        return this.$store.state['loader'].showmsg
      },
      set (newValue) {
        return this.$store.commit('loader/setmsgvisible', newValue)
      }
    },
  },
  methods: {
    isLoggedIn() {
      return isLoggedIn()
    }
  },
  async created() {

  }
}
</script>
<style lang="scss">
  @import './scss/custom';
  h1{
    margin-top: 30px;
  }

  .tooltitle{
    color: #5d5d5d !important;
    float:right;
    font-size: 18px;
    text-align: right;
    line-height: 20px !important;
    span{
      font-size: 14px !important;
    }
  }

  #maincontent{
    height: 100%;
  }

  .v-snack__wrapper{
    box-shadow: none !important;
  }
</style>