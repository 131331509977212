<template>
  <div id="account">
    <v-container>
      <v-row>
        <v-col cols="12">
          <h1 class="font-weight-light primary--text mb-0">
            My Analyzes
          </h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <div class="flex d-inline-flex">
            <v-chip
                class="mt-6 mr-6 flex"
                label
                rounded
                color="secondary"
                small
            >
              {{ filteredAnalysis.length }}
            </v-chip>
          </div>
          <div class="flex d-inline-flex" style="width: 450px;">
            <v-text-field v-model="search" clearable light placeholder="Search by analysis name" autocomplete="off"></v-text-field>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card flat outlined class="px-8 py-8">
            <v-row>
              <v-col cols="12">
                <v-row>
                  <v-col>
                     <span v-if="analysis !== undefined && analysis.length === 0">
                        No data available
                      </span>
                    <div v-else>
                      <div v-for="(a,index) in paginatedData" :key="'analysis_'+index">
                        <v-row class="mt-2 mb-1">
                          <v-col cols="3">
                            <div style="background: #eee;height: 165px;">
                              <v-row>
                                <v-col cols="12" class="pl-8">
                                  <div v-html="getHighlightSearchName(a.name)">>

                                  </div>
                                  <div class="caption">
                                    {{ a.description }}
                                     created<br>
                                    {{ moment(a.created_at).locale("de").format('DD.MM.YYYY - HH:mm') }}<br>
                                      <v-chip
                                          class="mt-4"
                                          label
                                          :class="statusColor(a.status)"
                                          x-small
                                      >Status: {{ a.status }}
                                     </v-chip>
                                    <div class="mt-2" v-if="a.msg">
                                      Response: {{ a.msg }}
                                    </div>
                                  </div>
                                </v-col>
                              </v-row>
                            </div>
                          </v-col>
                          <v-col cols="3">
                            <div style="background: #eee;height: 165px;">
                              <v-row>
                                <v-col cols="12" class="pl-8">
                                  {{ a.eyedata.name }}

                                  <div class="caption">
                                    {{ a.eyedata.description }}
                                  </div>
                                  <div v-if="a.preprocessor">
                                     <v-chip
                                         class="mt-2 bg-blue"
                                         label
                                         x-small
                                     > {{ a.preprocessor.name }}
                                      </v-chip>
                                  </div>
                                </v-col>
                              </v-row>
                            </div>
                          </v-col>
                          <v-col cols="3">
                            <div style="background: #93e995;height: 165px;">
                              <v-row>
                                <v-col cols="12" class="pr-8 px-8">
                                  <span class="caption">
                                  <b>{{ a.endpoint.name }}</b><br>
                                  {{ a.endpoint.description }}
                                  </span>
                                </v-col>
                              </v-row>
                            </div>
                          </v-col>
                          <v-col cols="3">
                            <div style="background: #eee;height: 165px;">
                              <v-row>
                                <v-col cols="12" class="pl-8">
                                <span class="caption">
                                  <div v-if="diffTime(a.created_at) && a.status == 'SUCCESS' ">
                                    <div class="mt-1">delete in</div>
                                     <v-chip
                                         :key="a.id+'_rerender_'+rerenderTimer"
                                         class="mt-2 bg-red text--white"
                                         label
                                         x-small
                                     > {{ diffTime(a.created_at) }}
                                    </v-chip>
                                    <div>
                                     <v-btn class="mt-5 ml-0 px-6" color="secondary" @click="requestDownload(a)" depressed small>
                                        Download
                                      </v-btn>
                                    </div>
                                  </div>
                                  <div v-if="!diffTime(a.created_at) || a.status === 'DELETED'">
                                    Files / download automatically deleted after 5 days
                                  </div>
                                   <div v-if="diffTime(a.created_at) && (a.status === 'WAITING' || a.status === 'PROCESSING')">
                                    As soon as the status is changed from "pending" to "ready" you can download the results here.
                                  </div>
                                </span>
                                </v-col>
                              </v-row>
                            </div>
                          </v-col>
                        </v-row>
                        <v-divider light class="mt-4 mb-6"></v-divider>
                      </div>
                      <v-pagination :key="'pag'+this.pageCount" total-visible="5" v-model="pageNumber" light  color="secondary" :length="pageLength" @input="nextPage" />
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
          <br><br>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import {analysisActions, analysisGetters, analysisMutations} from "../components/observables/analysis";
import httpClient from "../utils/httpClient";
import moment from 'moment';

export default {
  name: 'MyAnalyzes',
  data() {
    return {
      moment: moment,
      pageNumber: 1,
      search: '',
      size: 5,
      rerenderTimer: 0,
      pendingTimer: false,
      timer: false,
    }
  },

  watch:{
    search(){
      this.pageNumber = 1;
    }
  },

  computed: {
    ...analysisGetters,
    paginatedData() {
      if(this.filteredAnalysis.length > 0){
        const start = this.pageNumber * this.size - this.size,
            end = start + this.size;
        return this.filteredAnalysis.slice(start, end);
      }
    },

    pageCount() {
      let l = this.filteredAnalysis.length,
          s = this.size;
      return Math.ceil(l / s);
    },

    pageLength(){
      if(isNaN(Math.ceil(this.filteredAnalysis.length/this.size))){
        return 1;
      }else{
        return Math.ceil(this.filteredAnalysis.length/this.size)
      }
    },

    pendingStacks(){

      return this.analysis.filter(function(analysis) {

        return (analysis.status === 'WAITING' || analysis.status === 'PROCESSING');

      });
    },

    filteredAnalysis() {

      if (!this.search) {
        this.search = '';
      }

      if (this.analysis !== undefined) {

        var filterdAnalysis = this.analysis.filter(o => {
          try {
            return (
                (o.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1)
            )
          } catch (e) {
            return false;
          }
        })
        return filterdAnalysis;
      } else {
        return false;
      }
    }
  },
  methods: {
    ...analysisActions,
    ...analysisMutations,

    async requestDownload(instance){
      let res = await httpClient({
        url: process.env.VUE_APP_API_URL+ process.env.VUE_APP_API_PATH+'request/download',
        method: 'post',
        responseType: 'blob',
        data: { instances: [instance] }
      })

      if(res != 'false' && res.size > 20){

        var fileURL = window.URL.createObjectURL(new Blob([res], { type: 'application/zip' }));
        var fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'titan-download-'+moment(this.month).format('YYYY-MM-DD-hh-mm-ss')+'.zip');
        document.body.appendChild(fileLink);
        fileLink.click();

      }else{
        this.$store.dispatch(`loader/hide`);
        this.$store.dispatch(`loader/setmsg`,'no download available');
        this.$store.dispatch(`loader/setmsgtype`,'error');
        this.$store.dispatch(`loader/setmsgvisible`,true);
      }
    },

    statusColor(status){

      if(status === 'SUCCESS'){
        return 'bg-green'
      }

      if(status === 'DELETED' || status === 'ERROR'){
        return 'bg-red'
      }

      if(status === 'WAITING' || status === 'PROCESSING'){
        return 'bg-orange'
      }
    },

    getHighlightSearchName(textVal) {

      if (this.search == '') {
        return textVal;
      }

      return textVal.replace(new RegExp(this.search, "gi"), match => {
        return '<span class="highlightText">' + match + '</span>';
      });
    },

    nextPage(page) {
      this.pageNumber = page;
    },

    diffTime: function(analysisDate){

      var a = moment(analysisDate).add(5,'days');
      var b = moment();

      var duration = moment.duration(a.diff(b));

      if(duration.milliseconds() < 0){
        return false;
      }else{
        return Math.floor(duration.asDays())+' days '+duration.hours()+'h '+duration.minutes()+'m ' +duration.seconds()+'s'
      }
    },

    async checkPending(){
      if(this.pendingStacks.length > 0) {
        let res = await httpClient({
          url: process.env.VUE_APP_API_URL + process.env.VUE_APP_API_PATH + 'check/pending',
          method: 'post',
          data: {instances: this.pendingStacks, responseType: 'analysis'}
        })

        if (res.type === 'success' && res.analysis) {
          this.setAnalysis(res.analysis)
        }
      }
    }
  },

  created() {
    this.fetchAnalysis();
  },

  mounted() {

    var self = this;

    this.timer = setInterval(function(){
      self.rerenderTimer = self.rerenderTimer +1;
    }, 1000)

    this.pendingTimer = setInterval(function(){

      self.checkPending();

    }, 8000)

  },

  destroyed: function(){
    clearInterval( this.timer )
    clearInterval( this.pendingTimer )
  },
}
</script>