<template>
  <div id="account">
    <v-container>
      <v-row>
        <v-col cols="12">
          <h1 class="font-weight-light primary--text mb-0">
            Settings
          </h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card flat outlined class="px-8 py-8">
            <v-row>
              <v-col cols="12">
                <v-row>
                  <v-col>
                    <h3 class="mb-2">
                      Notifications
                    </h3>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-switch
                        class="mt-0 flex d-inline-block mr-4"
                        small
                        dense
                        @change="updateNotifications(profile)"
                        v-model="profile.notification_analysis_changes"
                        :true-value="1"
                        :false-value="0"
                        inset
                        label="E-Mail notification if the status from my analyzes changes"
                    ></v-switch>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-switch
                        class="mt-0 flex d-inline-block mr-4"
                        small
                        dense
                        @change="updateNotifications(profile)"
                        v-model="profile.notification_preprocessor_changes"
                        :true-value="1"
                        :false-value="0"
                        inset
                        label="E-Mail notification if the status from my pre-processors changes"
                    ></v-switch>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
          <br><br>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import {profileActions,profileGetters} from "../components/observables/profile";

export default {
  name: 'Settings',
  data() {
    return {

    }
  },

  methods: {
    ...profileActions

  },
  computed: {

    ...profileGetters,
  },

  created() {
    this.fetchProfile();
  }

}
</script>