<template>
  <div id="account">
    <v-container>
      <v-row>
        <v-col cols="12">
          <h1 class="font-weight-light primary--text mb-0">
            Dashboard
          </h1>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-card flat outlined class="px-8 py-8">
            <v-row>
              <v-col cols="7">
                <div class="body-1 pt-0">
                    <h3>Welcome and have fun with our AI services</h3>
                Thank you for your support so we can continue to provide this<br>service in the future.
                </div>
              </v-col>
              <v-col cols="1"></v-col>
              <v-col cols="4">
                <!--<form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_blank">
                  <input name="cmd" type="hidden" value="_xclick"/>
                  <input name="business" type="hidden" value="info@getoct.ch"/>
                  <input name="lc" type="hidden" value="CH"/>
                  <input name="item_name" type="hidden" value="Donation AI-Service"/>
                  <input name="button_subtype" type="hidden" value="services"/>
                  <input name="no_note" type="hidden" value="0"/>
                  <input name="currency_code" type="hidden" value="CHF"/>
                  <input name="tax_rate" type="hidden" value="0"/>
                  <input name="shipping" type="hidden" value="0"/>
                  <input name="bn" type="hidden" value="PP-BuyNowBF:btn_buynowCC_LG.gif:NonHostedGuest"/>
                  <input name="option_index" type="hidden" value="0"/>
                  <input alt="Jetzt einfach, schnell und sicher online bezahlen – mit PayPal." name="submit" target="_blank" style="margin-top: -15px;"
                         :src="require('@/assets/img/donate.png')" type="image" width="165"/>

                  <img alt="" border="0" height="1" src="https://www.paypalobjects.com/de_DE/i/scr/pixel.gif" width="1"/>
                </form>-->
              </v-col>
            </v-row>
          <v-row>
              <v-col cols="12">
          <span>
           <v-icon color="secondary" size="20" style="margin-top: -4px;display: inline-block">
             mdi-shield-lock-outline
           </v-icon>
            <span class="pt-2 pl-2">
               <b>Security info</b>:<br>
              <ul class="mt-4 block">
                <li>Your data will be automatically deleted after 5 days</li>
                <li>Your data will NEVER be published over the internet and is NEVER reachable via URL</li>
                <li>All our servers are located in Switzerland</li>
              </ul>
            </span>
          </span>
              </v-col>
            </v-row>
            <v-divider light class="mt-12"></v-divider>
            <v-row class="mt-12 mb-16">
              <v-col cols="3">
                <v-card class="py-4 px-4" rounded flat style="border: 1px #eee solid;">
                  <span>
                    Total analyzes done
                  </span>
                  <div class="" style="font-size: 32px">
                    <span class="text--green">{{ dashboard.totalAnalyzes }}</span>
                  </div>
                </v-card>
              </v-col>
              <v-col cols="1" >
              </v-col>
              <v-col cols="3">
                <v-card class="py-4 px-4" rounded flat style="border: 1px #eee solid;">
                  <span>
                    Total preprocessors done
                  </span>
                  <div class="" style="font-size: 32px">
                    <span class="text--blue">{{ dashboard.totalPreprocessors }}</span>
                  </div>
                </v-card>
              </v-col>
              <v-col cols="1" >
              </v-col>
              <v-col cols="3">
                <v-card class="py-4 px-4" rounded flat style="border: 1px #eee solid;">
                  <span>
                    Current EyeData
                  </span>
                  <div class="" style="font-size: 32px">
                    <span class="text--grey">{{ dashboard.currentEyeData }}</span>
                  </div>
                </v-card>
              </v-col>
            </v-row>

            <v-row class="mt-12">
              <v-col cols="7" >
                <bar-chart :chart-data="dataHistory"></bar-chart>
              </v-col>
              <v-col cols="1" >
              </v-col>
             <!-- <v-col cols="4">
                <pie-chart :chart-data="dataStatus"></pie-chart>
              </v-col>-->
            </v-row>

          </v-card>
          <br><br>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>

import LineChart from "../components/charts/LineChart";
import BarChart from "../components/charts/BarChart";
import PieChart from "../components/charts/PieChart";
import RadarChart from "../components/charts/RadarChart";
import {dashboardGetters,dashboardActions} from "../components/observables/dashboard";
export default {
  name: 'Dashboard',
  components:{
    BarChart,
    LineChart,
    PieChart,
    RadarChart
  },
  data(){
    return {
      dataHistory: {},
      dataStatus: {}
    }
  },

  computed:{
    ...dashboardGetters
  },

  methods:{

    ...dashboardActions,

    setHistoryData () {

      this.dataHistory = {
        labels: this.dashboard.last4months,
        datasets: [
          {
            label: 'Analysis',
            backgroundColor: 'rgba(147,233,149,0.5)',
            data: this.dashboard.analyzesLast4Count
          }, {
            label: 'Preprocessor',
            backgroundColor: 'rgba(117,209,253,0.5)',
            data: this.dashboard.preprocessorsLast4Count
          }
        ],
        options:{}
      }
    },
    setStatusData () {
      this.dataStatus = {
        labels: ['finished','pending','failed'],
        datasets: [
          {
            data: [this.dashboard.finished, this.dashboard.pending, this.dashboard.failed],
            backgroundColor: [
              'rgba(147,233,149,0.5)',
              'rgba(233,193,147,0.5)',
              'rgba(233,147,147,0.5)',
            ],
          }
        ],
        options:{}
      }
    },

    async prepeareData () {
      await this.fetchDashboard();
      this.setHistoryData();
      this.setStatusData();
    }
  },


  created() {
    this.prepeareData();
  }

}
</script>