import Vue from 'vue'
import httpClient from "@/utils/httpClient";

const logState = Vue.observable({
    "log": []
});


export const logGetters = {
    log: () => logState.log
}

export const logMutations = {

    setLog(log) {
        logState.log = log;
    }
}

export const logActions = {
    fetchLog(){
        httpClient({
            url: process.env.VUE_APP_API_URL+ process.env.VUE_APP_API_PATH+'get/activity-log',
            method: 'GET'
        }).then(function (res) {
            logMutations.setLog(res.log)
        })
    },
}

export default {
    logGetters,
    logMutations,
    logActions
}