<template>
  <v-navigation-drawer id="rightNav" permanent fixed color="primary" :style="'background-image: url('+require('@/assets/img/login-bg-2021.jpg')+')' " expand-on-hover left style="z-index:5;border:none !important;background-position: right">
    <v-list class="mt-0 mb-0">
      <v-list-item class="px-2 mt-0">
        <v-list-item-avatar>
          <v-btn class="mx-2"  link to="/my-profile" fab dark small color="secondary">
            {{ $store.state.benutzer.info.vorname.substring(0,1) }}{{ $store.state.benutzer.info.name.substring(0,1) }}
          </v-btn>
        </v-list-item-avatar>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            {{ $store.state.benutzer.info.vorname }} {{ $store.state.benutzer.info.name }}
          </v-list-item-title>
          <v-list-item-subtitle>{{ $store.state.benutzer.info.email }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-list nav dense>
      <div class="top-nav">
        <v-divider color="white" class="my-4"></v-divider>
        <v-list-item link to="/dashboard">
          <v-list-item-icon>
            <v-icon>mdi-monitor-dashboard</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="subtitle-1 font-weight-light">Dashboard</v-list-item-title>
        </v-list-item>
        <v-divider color="white" class="my-4"></v-divider>
        <v-list-item link to="/eye-data">
          <v-list-item-icon>
            <v-icon>mdi-eye-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="subtitle-1 font-weight-light">Eye Data / Image Stacks</v-list-item-title>
        </v-list-item>
        <v-divider color="white" class="my-4"></v-divider>
        <v-list-item link to="/preprocessors">
          <v-list-item-icon>
            <v-icon>mdi-relative-scale</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="subtitle-1 font-weight-light">Pass trough Pre-Processor</v-list-item-title>
        </v-list-item>
        <v-divider color="white" class="my-4"></v-divider>
        <v-list-item link to="/create-analysis">
          <v-list-item-icon>
            <v-icon>mdi-folder-plus-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="subtitle-1 font-weight-light">Create Analysis</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/my-analyzes">
          <v-list-item-icon>
            <v-icon>mdi-cloud-download-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="subtitle-1 font-weight-light">My Analyzes</v-list-item-title>
        </v-list-item>
        <v-divider color="white" class="my-4"></v-divider>
        <v-list-item link to="/my-profile">
          <v-list-item-icon>
            <v-icon>mdi-account-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="subtitle-1 font-weight-light">My Profile</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/settings">
          <v-list-item-icon>
            <v-icon>mdi-cog-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="subtitle-1 font-weight-light">Settings</v-list-item-title>
        </v-list-item>
        <div v-if="this.$store.state.benutzer.info.superadmin == 1">
          <v-divider color="white" class="my-4"></v-divider>

          <div style="position: relative;">
            <v-list-item link to="/ai-endpoints">
              <v-list-item-icon>
                <v-icon>mdi-database-marker-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="subtitle-1 font-weight-light">AI-Endpoints </v-list-item-title>
            </v-list-item>
            <v-list-item  link to="/user-management">
              <v-list-item-icon>
                <v-icon>mdi-account-group</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="subtitle-1 font-weight-light">User Management </v-list-item-title>
            </v-list-item>
            <v-list-item  link to="/activity-log">
              <v-list-item-icon>
                <v-icon>mdi-message-text-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="subtitle-1 font-weight-light">Activity Log </v-list-item-title>
            </v-list-item>

            <div class="adminmenu-caption">
              Administrator
            </div>
          </div>
        </div>



        <v-divider color="white" class="my-4"></v-divider>
        <v-list-item link  @click="logoutUser()">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="subtitle-1 font-weight-light">Logout</v-list-item-title>
        </v-list-item>
     </div>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
import { logoutUser } from '../../utils/auth'

  export default {
    name: 'Navigation',
    methods: {
      logoutUser() {
        logoutUser();
        this.$router.push('/login')
      }
    }
  }
</script>
<style lang="scss">
#rightNav{
  i,.v-list-item__title,.v-list-item__subtitle{
    color: #fff;
  }

}

</style>