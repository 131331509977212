import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from "@/views/Login";
import Dashboard from "@/views/Dashboard";
import UserManagement from "@/views/UserManagement";
import AIEndpoints from "@/views/AIEndpoints";
import EyeData from "@/views/EyeData";
import Preprocessors from "@/views/Preprocessors";
import CreateAnalysis from "@/views/CreateAnalysis";
import MyAnalyzes from "@/views/MyAnalyzes";
import MyProfile from "@/views/MyProfile";
import Settings from "@/views/Settings";
import Passwortreset from "@/views/Passwortreset";
import ActivityLog from "../views/ActivityLog";
import {store} from "../store";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: {
      name: "login"
    }
  },
  {
    path: "/new-password",
    name: "passwortreset",
    component: Passwortreset,
    meta: {
      allowAnonymous: true
    }
  },
  {
    path: '/dashboard',
    name: "dashboard",
    component: Dashboard
  },
  {
    path: '/activity-log',
    name: "ActivityLog",
    component: ActivityLog
  },
  {
    path: '/eye-data',
    name: "EyeData",
    component: EyeData
  },
  {
    path: '/preprocessors',
    name: "Preprocessors",
    component: Preprocessors
  },
  {
    path: '/create-analysis',
    name: "CreateAnalysis",
    component: CreateAnalysis
  },
  {
    path: '/my-analyzes',
    name: "MyAnalyzes",
    component: MyAnalyzes
  },
  {
    path: '/my-profile',
    name: "MyProfile",
    component: MyProfile
  },
  {
    path: '/settings',
    name: "Settings",
    component: Settings
  },
  {
    path: '/user-management',
    name: "UserManagement",
    component: UserManagement
  },
  {
    path: '/ai-endpoints',
    name: "AIEndpoints",
    component: AIEndpoints
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      allowAnonymous: true
    }
  },
]

const router = new VueRouter({
  routes,
  mode: 'history',
})

//protected routes -> wenn nicht "allowAnonymous: true" dann redirect auf login sofern keine authorisierung vorhanden ist
router.beforeEach((to, from, next) => {
  if (!to.meta.allowAnonymous && (store.state.benutzer.id === undefined || store.state.benutzer.id == 0  || !store.state.benutzer.id)) {
    next({
      path: '/login',
      query: { redirect: to.fullPath }
    })
  }
  else {
    next()
  }
})

export default router