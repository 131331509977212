<template>
  <div id="forgotpw">
    <v-container>
      <v-row>
        <v-col cols="5">
          <h1 class="pwtooltitle mt-8 font-weight-bold">
            TITAN v2.1<br>
            <span class="font-weight-light">AI-Based Eye Analysis</span>
          </h1>
          <h2 class="text--white">Your new password</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <span v-if="msg" v-html="msg" class="msg">

          </span>
          <br><br><br>
          <a href="/">
            <v-btn color="primary" depressed >back to TITAN</v-btn>
          </a>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import httpClient from "../utils/httpClient";

export default {
  name: 'Passwortreset',
  data() {
    return {
      msg: '<b>PLEASE WAIT </b>Your new password is generated....'
    }
  },
  methods: {

    timeout(ms){
      return new Promise(resolve => setTimeout(resolve, ms));
    },

    async sendNewPassword(){

      await this.timeout(2500);

      const urlParams = new URLSearchParams(window.location.search);

      const res = await httpClient({
        url: process.env.VUE_APP_API_URL+'send/newpw',
        method: 'post',
        data: { 't': urlParams.get('t')}
      })

      this.msg = res.msg;

    }

  },
  created() {
    this.sendNewPassword();
  }
}
</script>
<style scoped lang="scss">
  #forgotpw{
    height: 100%;
    width: 100%;
    background-image: url("../assets/img/login-bg-2021.jpg");
    background-size: cover;
    margin: 0 !important;
  }
  h1{
    margin-bottom: 10px;
    margin-top: 50px;
    font-size: 55px;
    display: block;
    color: #fff;
    line-height: 60px;
    font-weight: 300;
  }

  .msg{
    color: #fff;
  }

  h2{
    margin-top: 100px;
    color: #fff;
  }
  .v-text-field {
    padding-top: 0 !important;
    margin-top: 0;
  }

  .pwtooltitle{
    color: #fff !important;
    font-size: 18px;
    text-align: left;
    margin-top: 20px;
    display: block;
    line-height: 20px !important;
      span{
        font-size: 14px !important;
      }
  }

  #maincontent{
    height: 100%;
  }

  .v-snack__wrapper{
    box-shadow: none !important;
  }
</style>