<template>
  <div id="account">
    <v-container>
      <v-row>
        <v-col cols="12">
          <h1 class="font-weight-light  primary--text mb-0">
            AI-Endpoints
          </h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card flat outlined class="px-8 py-8">
            <v-row>
              <v-col cols="12">
                <v-btn depressed @click="newEndpoint" color="secondary">
                  <v-icon>
                    mdi-plus-network-outline
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <h3>
                  Registered AI-Endpoints ({{ aiendpoints.length }})
                </h3>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <span v-if="aiendpoints.length === 0">
                  no AI-Endpoints available
                </span>
                <div v-for="(ep,index) in aiendpoints" :key="'endpoint_'+index">
                  <v-row class="mt-2 mb-1">
                    <v-col cols="4">
                      {{ ep.name }}  <v-chip
                        class="ma-2"
                        :class="ep.apitype  == 'analysis' ? 'bg-green' : 'bg-blue'"
                        label
                        x-small
                    >
                      {{ ep.apitype }}
                    </v-chip><br>
                      <span class="caption">
                         {{ ep.description }}
                      </span>
                    </v-col>
                    <v-col cols="4" class="caption pt-4">
                      {{ ep.url }}
                    </v-col>
                    <v-col cols="2" class="pt-3">
                      <div v-for="(param,index2) in ep.parameters" :key="'param_'+index2">
                        <v-row>
                          <v-col class="mb-1 pt-4 caption">
                            {{ param.key }} <span class="caption" v-if="param.defaultvalue != ''">({{ param.defaultvalue }})</span>
                          </v-col>
                        </v-row>
                        <v-divider light></v-divider>
                      </div>
                    </v-col>
                    <v-col cols="2">
                      <v-btn @click="prepareDeleteEndpoint(ep.id)" class="float-right ml-2" dense depressed small color="secondary">
                        <v-icon size="20">
                          mdi-delete-outline
                        </v-icon>
                      </v-btn>
                      <v-btn @click="editEndpoint(ep.id)" class="float-right" dense depressed small color="secondary">
                        <v-icon size="16">
                          mdi-application-edit-outline
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-divider light></v-divider>
                </div>
              </v-col>
            </v-row>
          </v-card>
          <br><br>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="detailModal" persistent max-width="800">
      <v-card>
        <v-card-title class="headline font-weight-normal mb-6">
         Edit AI-Endpoint
        </v-card-title>
        <v-card-text class="body-2">
          <v-row>
            <v-col cols="6">
              <v-text-field autocomplete="off" v-model="endpointObject.name" label="Name*"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field type="url" autocomplete="off" v-model="endpointObject.url" label="Full URL*"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-select
                  v-model="endpointObject.apitype"
                  :items="apitypes"
                  label="API-Type*"
                  item-text="name"
                  item-value="key"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-switch
                  class="mt-0 flex d-inline-block mr-4"
                  small
                  dense
                  v-model="endpointObject.eyesensitive"
                  :true-value="1"
                  :false-value="0"
                  inset
                  label="Image stacks must be specified as either right or left eye"
              ></v-switch>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="mt-0 pt-0">
              <v-textarea rows="2" w label="Description" v-model="endpointObject.description"></v-textarea>
            </v-col>
          </v-row>
          <v-divider light></v-divider>
          <v-row>
            <v-col class="body-1 font-weight-bold mt-6">
              Available Parameters
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <span v-if="endpointObject.parameters.length === 0">no params</span>
              <div v-for="(param,index) in endpointObject.parameters" :key="'param_'+index">
                <v-row>
                  <v-col cols="5">
                    <v-text-field type="text" autocomplete="off" v-model="param.key" label="Name*"></v-text-field>
                  </v-col>
                  <v-col cols="5">
                    <v-text-field type="text" autocomplete="off" v-model="param.defaultvalue" label="Default Value"></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-btn class="secondary mt-4" @click="removeParam(index)" small depressed>
                      <v-icon size="16">
                        mdi-minus-circle-outline
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-divider light></v-divider>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn class="secondary" @click="addParam" small>
                <v-icon size="16">
                  mdi-plus-circle-outline
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="danger" depressed @click="detailModal = false">Abort</v-btn>
          <v-btn color="secondary" depressed  @click="saveAiendpointAndCloseModal(endpointObject)">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import {aiendpointGetters, aiendpointActions} from '../components/observables/aiendpoints';

export default {
  name: 'AIEndpoints',
  data() {
    return {
      endpointObject:{
        id: -1,
        name: '',
        url: '',
        eyesensitive: 1,
        apitype: 'analysis',
        description: '',
        parameters: [],
      },

      endpointBaseObject:{
        id: -1,
        name: '',
        url: '',
        eyesensitive: 1,
        apitype: 'analysis',
        description: '',
        parameters: [],
      },

      apitypes: [
        {
          key: 'analysis',
          name: 'Analysis'
        },
        {
          key: 'preprocessor',
          name: 'Pre-Processor'
        }
      ],

      detailModal: false
    }
  },
  computed:{
    ...aiendpointGetters
  },
  methods: {
    ...aiendpointActions,
    ...aiendpointActions,

    async saveAiendpointAndCloseModal(aiendpoint){
      let res = await this.saveAiendpoint(aiendpoint);

      if(res == 'success'){
        this.detailModal = false;
      }
    },

    prepareDeleteEndpoint(id){

      this.$confirm('Really delete this endpoint with all parameters?', {
        title: 'Attention',
        buttonTrueText: 'Delete',
        buttonFalseText: 'Abort'
      }).then(async confirmed => {
        if (confirmed) {
         this.deleteAiendpoint(id)
        }
      })
    },

    newEndpoint(){
      this.detailModal = true;
      this.endpointObject = JSON.parse(JSON.stringify(this.endpointBaseObject));
    },

    editEndpoint(id){
      this.detailModal = true;
      this.endpointObject = this.aiendpoints.filter(function (el){
        return el.id === id;
      })[0];
    },

    addParam(){
      this.endpointObject.parameters.push({
        id: -1,
        key: '',
        defaultvalue: '',
        type: 'string'
      });
    },

    removeParam(index){
      this.endpointObject.parameters.splice(index,1);
    }
  },

  created() {
    this.fetchAiendpoints();
  },
}
</script>