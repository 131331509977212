import Vue from 'vue'
import Vuex from 'vuex'
import { loader } from './modules/loader';
import { benutzer } from './modules/benutzer';

import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex);

export const store = new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
  },
  modules: {
    loader, benutzer
  }
});