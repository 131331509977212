<template>
  <div id="account">
    <v-container>
      <v-row>
        <v-col cols="12">
          <h1 class="font-weight-light primary--text mb-0">
            User Management
          </h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <div class="flex d-inline-flex">
            <v-chip
                class="mt-6 mr-6 flex"
                label
                rounded
                color="secondary"
                small
            >
              {{ filteredUsers.length }}
            </v-chip>
          </div>
          <div class="flex d-inline-flex" style="width: 450px;">
            <v-text-field v-model="search" clearable light placeholder="Search by firstname / name / company / email" autocomplete="off"></v-text-field>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card flat outlined class="px-8 py-8">
            <div v-for="(u,index) in filteredUsers" :key="'user_'+index">
              <v-row>
                <v-col cols="3" class="body-1">
                  <span v-if="u.superadmin == 1" class="pr-2">
                    <v-icon size="20" color="secondary">
                      mdi-crown
                    </v-icon>
                  </span>
                  <span v-html="getHighlightSearchName(u.firstname)" class="pr-1">
                  </span>
                  <span v-html="getHighlightSearchName(u.name)">
                  </span>
                  <br>
                  <span v-if="u.company && u.company != ''" class="caption">
                    <span v-html="getHighlightSearchName(u.company)">
                    </span>
                    <br>
                  </span>
                  <span class="caption">
                    <span v-html="getHighlightSearchName(u.email)">
                    </span>
                  </span>
                </v-col>
                <v-col cols="3">
                  <v-switch
                      :disabled="$store.state.benutzer.id == u.id"
                      class="mt-0 flex d-inline-block mr-4"
                      small
                      dense
                      @change="userUpdate(u)"
                      v-model="u.activated"
                      :true-value="1"
                      :false-value="0"
                      inset
                      label="Login activated"
                  ></v-switch>
                  <br>
                  <v-switch
                      :disabled="$store.state.benutzer.id == u.id"
                      class="mt-0 flex d-inline-block mr-4"
                      small
                      dense
                      @change="userUpdate(u)"
                      v-model="u.superadmin"
                      :true-value="1"
                      :false-value="0"
                      inset
                      label="Administrator"
                  ></v-switch>
                  <div style="color: #7c7c7c;margin-top: -7px;">
                  <span class="caption">no notification mails are sent when<br>the status is changed</span>
                </div>
                </v-col>
                <v-col cols="6">
                  <div v-for="(ai,index2) in aiendpoints" :key="'endpoint_'+index+'_'+index2">
                    <v-row>
                      <v-col cols="5">
                        <v-switch
                            class="mt-0 mr-4"
                            small
                            dense
                            inset
                            :true-value="1"
                            :false-value="0"
                            :input-value="endpointActive(u.id,ai.id)"
                            @change="endpointStatus(u.id,ai.id)"
                            :label="ai.name"
                        ></v-switch>
                      </v-col>
                      <v-col cols="4">
                        <v-chip
                            class="mr-8"
                            :class="ai.apitype  == 'analysis' ? 'bg-green' : 'bg-blue'"
                            label
                            x-small
                        >
                          {{ ai.apitype }}
                        </v-chip>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
              <v-divider light class="mb-4"></v-divider>
            </div>
          </v-card>
          <br><br>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>

import {usersGetters, usersActions, usersMutations} from '../components/observables/users';
import {aiendpointActions, aiendpointGetters, aiendpointMutations} from '../components/observables/aiendpoints';

export default {
  name: 'UserManagement',
  data() {
    return {
      search: ''
    }
  },

  watch:{
    search(){
      this.pageNumber = 1;
    }
  },

  computed: {
    ...aiendpointGetters,
    ...usersGetters,

    filteredUsers() {

      if (!this.search) {
        this.search = '';
      }

      if (this.users !== undefined) {

        var filteredUsers = this.users.filter(o => {
          try {
            return (
                (o.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1 || o.firstname.toLowerCase().indexOf(this.search.toLowerCase()) > -1  || o.company.toLowerCase().indexOf(this.search.toLowerCase()) > -1 || o.email.toLowerCase().indexOf(this.search.toLowerCase()) > -1)
            )
          } catch (e) {
            return false;
          }
        })
        return filteredUsers;
      } else {
        return false;
      }
    }
  },
  methods: {
    ...aiendpointActions,
    ...usersActions,

    getHighlightSearchName(textVal) {

      if (this.search == '') {
        return textVal;
      }

      return textVal.replace(new RegExp(this.search, "gi"), match => {
        return '<span class="highlightText">' + match + '</span>';
      });
    },

    endpointActive(uid,endpointID){

      var user = this.users.filter(u => {
          return u.id == uid;
      })[0]

      var endpointActive = user.aiendpoints.filter(e => {
        return e.id == endpointID;
      })

      return endpointActive.length > 0 ? 1 : 0;
    },

    endpointStatus(uid,endpointID){
      this.updateEndpointStatus(uid,endpointID);
    },

    userUpdate(user){
      this.updateUser(user);
    },

  },
  created() {
    this.fetchUsers();
    this.fetchAiendpoints();
  },

}
</script>