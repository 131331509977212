import Vue from 'vue'
import httpClient from "@/utils/httpClient";

const analysisState = Vue.observable({
    "analysis": []
});


export const analysisGetters = {
    analysis: () => analysisState.analysis
}

export const analysisMutations = {
    setAnalysis(analysis) {
        analysisState.analysis = analysis;
    }
}

export const analysisActions = {
    fetchAnalysis(){
        httpClient({
            url: process.env.VUE_APP_API_URL+ process.env.VUE_APP_API_PATH+'get/analysis',
            method: 'GET'
        }).then(function (res) {
            analysisMutations.setAnalysis(res.analysis)
        })
    },

    async createAnalysis(instances){
        let res = await httpClient({
            url: process.env.VUE_APP_API_URL+ process.env.VUE_APP_API_PATH+'create/analysis',
            method: 'post',
            data: {'instances':instances}
        })

        if(res.type === 'success'){
            analysisMutations.setAnalysis(res.analysis)
        }

        return res.type;
    },
}

export default {
    analysisState,
    analysisGetters,
    analysisMutations,
    analysisActions
}