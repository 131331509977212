export const benutzer = {
  namespaced: true,
  state: {
    id: false,
    info:{
      name: '',
      vorname: '',
      email: '',
      superadmin: 0
    }
  },
  actions: {
    setID({ commit },value) {
      commit("setID",value);
    },
    setInfo({ commit },value) {
      commit("setInfo",value);
    }
  },
  mutations: {
    setID(state,val){
      state.id = val;
    },
    setInfo(state,val){
      state.info = val;
    }
  }
};