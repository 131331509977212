import Vue from 'vue'
import httpClient from "@/utils/httpClient";

const usersState = Vue.observable({
    "users": []
});


export const usersGetters = {
    users: () => usersState.users
}

export const usersMutations = {

    setUsers(users) {
        usersState.users = users;
    }
}

export const usersActions = {
    fetchUsers(){
        httpClient({
            url: process.env.VUE_APP_API_URL+ process.env.VUE_APP_API_PATH+'get/users',
            method: 'GET'
        }).then(function (res) {
            usersMutations.setUsers(res.users)
        })
    },

    updateUser(user){
        httpClient({
            url: process.env.VUE_APP_API_URL + process.env.VUE_APP_API_PATH + 'update/user',
            method: 'POST',
            data:{user: user}
        }).then(function (res) {
            usersMutations.setUsers(res.users)
        })
    },

    updateEndpointStatus(userID,endpointID){
        httpClient({
            url: process.env.VUE_APP_API_URL + process.env.VUE_APP_API_PATH + 'update/user-endpoint',
            method: 'POST',
            data:{userID: userID, endpointID: endpointID}
        }).then(function (res) {
            usersMutations.setUsers(res.users)
        })
    }
}

export default {
    usersGetters,
    usersMutations,
    usersActions
}