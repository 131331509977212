import Vue from 'vue'
import httpClient from "@/utils/httpClient";

const profileState = Vue.observable({
    "profile": []
});


export const profileGetters = {
    profile: () =>profileState.profile
}

export const profileMutations = {

    setProfile(profile) {
        profileState.profile = profile;
    }
}

export const profileActions = {
    fetchProfile(){
        httpClient({
            url: process.env.VUE_APP_API_URL+ process.env.VUE_APP_API_PATH+'get/profile',
            method: 'GET'
        }).then(function (res) {
            profileMutations.setProfile(res.profile)
        })
    },

    updateProfile(profile){
        httpClient({
            url: process.env.VUE_APP_API_URL + process.env.VUE_APP_API_PATH + 'update/profile',
            method: 'POST',
            data:{email: profile.email, firstname: profile.firstname, name: profile.name, company: profile.company}
        }).then(function (res) {
            profileMutations.setProfile(res.profile)
        })
    },

    updateNotifications(profile){
        httpClient({
            url: process.env.VUE_APP_API_URL + process.env.VUE_APP_API_PATH + 'update/notifications',
            method: 'POST',
            data:{notification_analysis_changes: profile.notification_analysis_changes, notification_preprocessor_changes: profile.notification_preprocessor_changes}
        }).then(function (res) {
            profileMutations.setProfile(res.profile)
        })
    },

    savePassword() {
        httpClient({
            url: process.env.VUE_APP_API_URL+ process.env.VUE_APP_API_PATH+'save/new-password',
            method: 'post',
            data: {pw1: this.pw1, pw2: this.pw2}
        })
    }
}

export default {
    profileGetters,
    profileMutations,
    profileActions
}