import Vue from 'vue'
import httpClient from "@/utils/httpClient";

const aiendpointState = Vue.observable({
    "aiendpoints": []
});


export const aiendpointGetters = {
    aiendpoints: () => aiendpointState.aiendpoints
}

export const aiendpointMutations = {

    setAiendpoints(aiendpoints) {
        aiendpointState.aiendpoints = aiendpoints;
    }
}

export const aiendpointActions = {
    fetchAiendpoints(){
        httpClient({
            url: process.env.VUE_APP_API_URL+ process.env.VUE_APP_API_PATH+'get/aiendpoints',
            method: 'GET'
        }).then(function (res) {
            aiendpointMutations.setAiendpoints(res.aiendpoints)
        })
    },

    fetchLoginUsersAiendpoints(endpointType){
        httpClient({
            url: process.env.VUE_APP_API_URL+ process.env.VUE_APP_API_PATH+'get/aiendpoints-from-user',
            method: 'POST',
            data: {endpointType: endpointType}
        }).then(function (res) {
            aiendpointMutations.setAiendpoints(res.aiendpoints)
        })
    },

    async saveAiendpoint(aiendpoint){

        let res = await httpClient({
            url: process.env.VUE_APP_API_URL+ process.env.VUE_APP_API_PATH+'save/aiendpoint',
            method: 'post',
            data: aiendpoint
        })

        if(res.type === 'success'){
            aiendpointMutations.setAiendpoints(res.aiendpoints)
        }

        return res.type;
    },

    deleteAiendpoint(id){
        httpClient({
            url: process.env.VUE_APP_API_URL+ process.env.VUE_APP_API_PATH+'delete/aiendpoint',
            method: 'post',
            data: { 'id': id }
        }).then(function (res) {
            if(res.type === 'success'){
                aiendpointMutations.setAiendpoints(res.aiendpoints)
            }
        })
    },
}

export default {
    aiendpointState,
    aiendpointGetters,
    aiendpointMutations,
    aiendpointActions
}